<template>
  <div class="analysis-wrap" :class="getSearchCondition ? 'analysis-grid' : ''">
    <search-analysis v-if="getSearchCondition"></search-analysis>
    <analysis-list></analysis-list>
  </div>
</template>
<script>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'analysis',
  components: {
    AnalysisList: defineAsyncComponent(() => import('@/components/analysis/AnalysisList.vue')),
    SearchAnalysis: defineAsyncComponent(() => import('@/components/analysis/SearchAnalysis.vue')),
  },
  setup() {
    const route = useRoute();
    const is_headquarters = sessionStorage.getItem('is_headquarters');
    const getSearchCondition = useGetters('analysis', ['getSearchCondition']);
    const doGetStoreList = useActions('common', ['doGetStoreList']);
    doGetStoreList();
    //離開清空vuex
    const RESET = useMutations('analysis', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/order/analysis')) RESET();
      }
    );
    return { route, getSearchCondition, is_headquarters };
  },
};
</script>
<style lang="scss" scoped>
.analysis-wrap {
  width: 100%;
  height: 100%;
}
.analysis-grid {
  display: grid;
  grid-template-rows: 64px auto;
  grid-gap: 16px;
}
</style>
